import { useState } from "react";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { NextSeo } from "next-seo";
import Script from "next/script";
import pickBy from "lodash/pickBy";

import { ISbStoryData } from "storyblok-js-client";
import {
  fetchBlockQueries,
  getGlobalPageData,
  getStoryblokStory,
} from "../lib/storyblok-api";
import { DynamicBlock } from "../blocks/DynamicBlock";
import PageTemplate from "../components/PageTemplate";
import { StoryProvider } from "./[...route]";

const resolveRelations = ["block.source", "journal-block.source"];

export default function NotFound(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const [previewStory, setPreviewStory] = useState<ISbStoryData>();
  const story = previewStory || props.notFoundStory;

  const currency = 'SEK';

  function initStoryblokBridge() {
    const storyblokInstance = new window.StoryblokBridge({
      resolveRelations,
    });

    storyblokInstance.on(["change", "published"], () => {
      location.reload();
    });

    storyblokInstance.on("input", (event) => {
      if (event.story.id === props.notFoundStory?.id) {
        setPreviewStory(event.story);
      }
    });
  }

  return (
    <StoryProvider {...props} story={story}>
      <NextSeo title={`${story?.name} – Artilleriet`} />

      <PageTemplate currency={currency}>
        <DynamicBlock block={story?.content} />
      </PageTemplate>

      {props.isWorkingInStoryblok && (
        <Script
          src="//app.storyblok.com/f/storyblok-v2-latest.js"
          onLoad={initStoryblokBridge}
        />
      )}
    </StoryProvider>
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  preview = false,
}) => {
  const isWorkingInStoryblok = process.env.NODE_ENV !== "production" || preview;
  const messages = await import(`../messages/${locale}.json`);

  const sbParams = {
    version: isWorkingInStoryblok ? "draft" : "published",
    language: locale === "sv" ? "sv" : "",
  };

  const notFoundStory = await getStoryblokStory("not-found", sbParams);

  const globalDataPromise = getGlobalPageData(
    sbParams.version,
    sbParams.language
  );

  const pageDataPromise = fetchBlockQueries(
    notFoundStory,
    resolveRelations,
    locale,
    'SEK',
    isWorkingInStoryblok
  );

  const [globalData, pageData] = await Promise.all([
    globalDataPromise,
    pageDataPromise,
  ]);

  return {
    revalidate: 10 * 60,
    props: pickBy({
      notFoundStory,
      ...globalData,
      ...pageData,
      isWorkingInStoryblok,
      messages: messages.default,
    }),
  };
};
